#container_coupon {
  text-align: center;
  letter-spacing: 0.1rem;
  height: 100vh;
  .inner {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    .head_logo {
      background-color: $primary;
      @include laptop() {
        padding: 20px;
      }
      @include cellPhone() {
        padding: 10px;
        img {
          zoom: 0.5;
        }
      }
    }
    .fix_box {
      background-color: $light;
      position: relative;
    }
    .fix_box.fix {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  .group {
    color: $primary;
    padding: 5px;
    font-weight: bold;
    background: $light;
    @include laptop() {
      font-size: 30px;
    }
    @include cellPhone() {
      font-size: 15px;
    }
    .description {
      @include laptop() {
        font-size: 20px;
      }
      @include cellPhone() {
        font-size: 10px;
      }
    }
  }
  .scan {
    @include cellPhone() {
      padding: 15px 0px;
    }
  }
  .code {
    padding-bottom: 10px;
    letter-spacing: 0.15em;
    font-family: "Noto Sans Japanese", sans-serif;
    @include laptop() {
      font-size: 24px;
    }
    @include cellPhone() {
      font-size: 12px;
      padding: 15px 0px;
    }
    .code-box {
      display: inline-block;
      border: 1px solid black;
      border-radius: 10px;
      padding: 10px 80px;
      @include laptop() {
        font-size: 30px;
      }
      @include cellPhone() {
        font-size: 24px;
      }
      margin-top: 5px;
    }
  }
  .item-title {
    color: $danger;
    font-weight: bold;
    @include laptop() {
      font-size: 30px;
    }
    @include cellPhone() {
      font-size: 24px;
    }
  }
  @include cellPhone() {
    .barcode {
      img {
        width: 100%;
        height: auto;
      }
    }
    .coupon_img {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
