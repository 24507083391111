html {
  height: 100%;
}

body {
  background-color: $primary;
  margin: 0;
  padding: 0;
}

body.coupon {
  background: none;
}

hr {
  margin: 0;
  border: 0.5px solid $secondary;
}

@include cellPhone() {
  * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
