.error-message {
  color: $danger;
  font-weight: bold;
  background: $warning;
  @include laptop() {
    font-size: 3rem;
    width: 580px;
    margin: -30px auto 20px;
  }
  @include cellPhone() {
    font-size: 1.5rem;
    width: 290px;
    margin: -10px auto 20px;
  }
}

#container {
  text-align: center;
  letter-spacing: 0.1rem;
  @include laptop() {
    background-color: $primary;
    height: 100vh;
  }
  .inner {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    @include laptop() {
      padding: 80px 20px 180px;
    }
    @include cellPhone() {
      padding: 50px 20px 180px;
    }
  }
  h1 {
    color: $light;
    margin: 0;
    @include laptop() {
      font-size: 34px;
      margin: 30px 0 45px;
    }
    @include cellPhone() {
      font-size: 17px;
      margin: 17px 0 25px;
    }
  }
  p {
    color: $light;
    @include laptop() {
      margin-bottom: 75px;
      font-size: 28px;
      line-height: 36px;
    }
    @include cellPhone() {
      margin-bottom: 35px;
    }
  }
  .login-input {
    display: block;
    padding: 15px 10px;
    color: $secondary;
    border: solid 1px $light;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    @include laptop() {
      font-size: 28px;
      width: 580px;
      margin: 0 auto 45px;
      height: 110px;
      -moz-border-radius: 10px;
      border-radius: 10px;
    }
    @include cellPhone() {
      font-size: 16px;
      width: 100%;
      max-width: 290px;
      margin: 0 auto 20px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }
    &::-webkit-input-placeholder {
      font-weight: bold;
      font-family: "Noto Sans Japanese", sans-serif;
      letter-spacing: 0.15rem;
      @include laptop() {
        font-size: 28px;
      }
      @include cellPhone() {
        font-size: 16px;
      }
    }
    &:-moz-placeholder {
      font-weight: bold;
      font-family: "Noto Sans Japanese", sans-serif;
      letter-spacing: 0.15rem;
      @include laptop() {
        font-size: 28px;
      }
      @include cellPhone() {
        font-size: 16px;
      }
    }
    &::-moz-placeholder {
      font-weight: bold;
      font-family: "Noto Sans Japanese", sans-serif;
      letter-spacing: 0.15rem;
      @include laptop() {
        font-size: 28px;
      }
      @include cellPhone() {
        font-size: 16px;
      }
    }
    &:-ms-input-placeholder {
      font-weight: bold;
      font-family: "Noto Sans Japanese", sans-serif;
      letter-spacing: 0.15rem;
      @include laptop() {
        font-size: 28px;
      }
      @include cellPhone() {
        font-size: 16px;
      }
    }
  }
  .login-btn {
    background-color: $orange;
    color: $light;
    border: 0px solid;
    font-weight: bold;
    cursor: pointer;
    &:disabled {
      background-color: $secondary !important;
    }
    @include laptop() {
      font-size: 28px;
      width: 580px;
      height: 115px;
      border-radius: 10px;
    }
    @include cellPhone() {
      font-size: 16px;
      width: 290px;
      height: 60px;
      border-radius: 5px;
    }
  }
  .link-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: $dark-orange;
    color: $light;
    border: 0px solid;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;

    text-decoration: none;
    @include laptop() {
      font-size: 24px;
      width: 580px;
      height: 115px;
      border-radius: 10px;
    }
    @include cellPhone() {
      font-size: 16px;
      width: 290px;
      height: 60px;
      border-radius: 5px;
      padding: 10px;
    }
  }
  .input-text{
    @include laptop() {
      margin-top: 75px;
    }
    @include cellPhone() {
      margin-top: 35px;
    }
  }
}
