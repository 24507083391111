@charset "UTF-8";
html {
  font-size: 10px;
}

body,
td,
th,
input,
textarea {
  color: #000000;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック", Osaka, Tahoma, Verdana, Arial, Verdana,
    sans-serif;
  font-size: 1.3rem;
  line-height: 1.5;
}

body {
  overflow-wrap: break-word;
}

a,
a:link,
a:visited,
a:active,
a:hover,
a:hover img {
  color: #000000;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

img {
  vertical-align: bottom;
}

.img_ck {
  font-size: 0.1rem;
  line-height: 0;
}

/* clearfix (micro) */
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* For IE 6/7 only */
.clearfix {
  *zoom: 1;
}

div,
p {
  /*word-break: break-all;*/
}

h2,
h3,
h4,
h5,
h6,
div,
p,
ul,
ol,
li,
dl,
dt,
dd,
img,
nav {
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  list-style: none;
}

.file {
  border-top: 1px dotted #cccccc;
  margin: 15px 0;
  padding: 10px 0 0;
  text-align: left;
}

.top-right {
  float: right;
  margin: 0px 0px 30px 30px;
}

.top-left {
  float: left;
  margin: 0px 30px 30px 0px;
}

.top-center {
  margin: 0px 0px 30px 0px;
  text-align: center;
}

.bottom-right {
  float: right;
  margin: 30px 0px 0px 30px;
}

.bottom-left {
  float: left;
  margin: 30px 30px 0px 0px;
}

.bottom-center {
  margin: 30px 0px 0px 0px;
  text-align: center;
}

.entry-container b {
  font-weight: bold;
}

.entry-container i {
  font-style: italic;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}
