.notfound {
  background-color: $light;
  height: 100vh;
}

.error-title {
  padding: 10px;
  margin: 0;
}

.error-content {
  padding: 10px;
  margin: 0;
}

.font-bold {
  font-weight: bold;
}
