@mixin laptop() {
  @media screen and (min-width: 769px) {
    @content;
  }
}

@mixin cellPhone() {
  @media screen and (max-width: 768px) {
    @content;
  }
}
